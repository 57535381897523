import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Index from "./index";
import Pricing from "./pricing";
import Portfolio from "./portfolio";
import ProjectDesc from "./projectDesc";
import ContactUs from "./ContactUs.jsx"

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Index />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/portfolio",
      element: <Portfolio />,
    },
    {
      path: "/project/:id",
      element: <ProjectDesc />,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default Router;
