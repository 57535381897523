import React, { useEffect, useState } from "react";
import { Navigation } from "./navigation";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import projects from "../data/project.json";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <section>
      <Navigation />
      <div className="portfolio-container">
        <h1 className="page-title">My Projects</h1>
        {projects.map((x) => {
          return (
            <div className="project-list">
              <div className="project-item">
                <img
                  src={x.images}
                  alt="Project 1 Screenshot"
                  className="project-image"
                />
                <div className="project-info">
                  <h2 className="project-name">{x.name}</h2>
                  <Link to={`/project/${x.value}`} className="project-link">
                    View Details
                  </Link>
                  <Link to={x.demoLink} className="project-link">
                    Live Demo
                  </Link>
                  <Link to={x.gihub} className="project-link">
                    Code Repository
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Contact data={landingPageData.Contact} />
    </section>
  );
};

export default Portfolio;
