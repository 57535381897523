import React from 'react'
import { Navigation } from './navigation'

const ContactUs = () => {
  return (
    <div>
      <Navigation />

    </div>
  )
}

export default ContactUs
