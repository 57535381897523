import React from "react";
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div>
            <a
              className="navbar-brand page-scroll"
              style={{
                padding: "0px",
                display: "flex",
                "alignItems": "center",
                gap: "6px",
              }}
              href="#page-top"
            >
              <img
                src="/img/logo/transperent.png"
                alt="logo"
                style={{ height: "100%" }}
              />
              <h5>Gokul Tech Ventures</h5>
            </a>{" "}
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/" className="page-scroll">
                Home
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="page-scroll">
                Pricing
              </Link>
            </li>
            <li>
              <Link to="/portfolio" className="page-scroll">
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="https://blogs.gokultechventures.com/"
                className="page-scroll"
              >
                Blogs
              </Link>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
