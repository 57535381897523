import React, { useEffect, useState } from "react";
import { Navigation } from "./navigation";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import pricing from "../data/pricing.json";

const Pricing = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [plan, setPlan] = useState("monthly_web_development_plans");
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <section className="pricing-tables content-area">
        <div className="container">
          <div className="main-title text-center">
            <h1>Pricing</h1>
            <p>Affordable Plans for Every Business Need.</p>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
            {Object.keys(pricing).map((x) => {
              return (
                <button
                  className={`btn ${
                    x === plan && "btn-active"
                  } btn-outline pricing-btn button-theme`}
                  onClick={() => {
                    setPlan(x);
                  }}
                >
                  {pricing[x].name}
                </button>
              );
            })}
          </div>
          <div className="row">
            {pricing[plan].plans.map((x) => {
              return (
                <div className="col-sm-12 col-lg-4 col-md-4">
                  <div className="pricing featured">
                    {x.topRated && (
                      <div className="listing-badges">
                        <span className="featured">Featured</span>
                      </div>
                    )}
                    <br />
                    <div className="price-header price-header-2">
                      <div className="title">{x.name}</div>
                      <div className="price">{x.price}</div>
                    </div>
                    <div className="content">
                      <ul>
                        {x.features.map((y) => {
                          return <li>{y}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        <div>* Custom Plans available as per your business needs.</div>
        </div>
      </section>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Pricing;
