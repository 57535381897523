import React, { useEffect, useState } from "react";
import { Navigation } from "./navigation";
import JsonData from "../data/data.json";
import { Contact } from "./contact";
import projects from "../data/project.json";
import { Link, useParams } from "react-router-dom";

const ProjectDesc = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  let projectDetails = projects.find((x) => x.value === id);
  return (
    <section>
      <Navigation />
      <div className="portfolio-container">
        <div className="project">
          <h2 className="project-title">[{projectDetails.name}]</h2>
          <p className="project-overview">{projectDetails.subtitle}</p>
          <div className="project-features">
            <h3>Key Features</h3>
            <ul>
              {projectDetails.keyFeatures.map((x) => {
                return <li>{x}</li>;
              })}
            </ul>
          </div>
          <div className="project-technologies">
            <h3>Technologies Used</h3>
            <p>Frontend: {projectDetails.technologiesUsed.Frontend}</p>
            <p>Backend: {projectDetails.technologiesUsed.Backend}</p>
            <p>Database: {projectDetails.technologiesUsed.Database}</p>
            <p>DevOps: {projectDetails.technologiesUsed.DevOps}</p>
            <p>
              Version Control:{" "}
              {projectDetails.technologiesUsed["Version Control"]}
            </p>
          </div>
          <div className="project-challenges">
            <h3>Challenges & Solutions</h3>
            {projectDetails.challengesAndSolutions.map((x) => {
              return (
                <>
                  <p>
                    <strong>Challenge:</strong> {x.challenge}
                  </p>
                  <p>
                    <strong>Solution:</strong> {x.solution}
                  </p>
                </>
              );
            })}
          </div>
          <div className="project-outcome">
            <h3>Outcome & Impact</h3>
            <ul>
              {projectDetails.outcomeAndImpact.map((x) => {
                return <li style={{listStyle:"inside"}}>{x}</li>;
              })}
            </ul>
          </div>
          <div className="project-links">
            <h3>Visuals & Links</h3>
            <Link to={projectDetails.demoLink} target="_blank">
              Live Demo
            </Link>{" "}
            | &nbsp;
            <Link to={projectDetails.gihub} target="_blank">
              GitHub Repository
            </Link>
            <div className="project-image">
              <img src={projectDetails.images} alt="[Project Name] Screenshot" />
            </div>
          </div>
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </section>
  );
};

export default ProjectDesc;
